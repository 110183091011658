
// import { Routes, Route, Navigate, useLocation, useRoutes } from "react-router-dom";
import './App.css';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/en';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import zhHK from 'antd/locale/zh_HK';
import { IntlProvider } from 'react-intl';
import { loadLocale } from './locale/index';
import { Provider as ReduxProvider } from 'react-redux';
import UserProvider from "./UserProvider";
import store from './redux/store';

import Routers from "./router";
import { useEffect, useMemo, useState } from "react";

function App() {
  // const location = useLocation();
  const [localeName, setLocaleName] = useState('en');

  const { locale, message } = useMemo(() => {
    return loadLocale(localeName)
  }, [localeName]);

  // const elements = useRoutes(routes)

  useEffect(() => {
    const name = localStorage.getItem('localeName');
    if (name) {
      setLocaleName(name);
      if (name === 'cn') {
        dayjs.locale('zh-cn');
      }
      else if (name === 'hk') {
        dayjs.locale('zh-hk');
      }
      else {
        dayjs.locale('en');
      }
    }
    else {
      setLocaleName('en');
      dayjs.locale('en');
      localStorage.setItem('localeName', 'en');
    }

  }, [])

  return (
    <ReduxProvider store={store}>
      <IntlProvider locale={locale} messages={message}>
        <ConfigProvider locale={localeName == 'cn' ? zhCN : localeName == 'hk' ? zhHK : enUS}>
          {/* {elements} */}
          <Routers />
          <UserProvider />
        </ConfigProvider>
      </IntlProvider>
    </ReduxProvider>
  );
}

export default App;
