import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setUserInfo, setPermissions, setRoleList } from './redux/userSlice'
import { getPermissionsList, getUserList as getRoleList } from './api/roleApi';
import { getProfile } from './api/loginApi';
import { useNavigate } from "react-router-dom";

function UserProvider() {

  const dispatch = useDispatch();
  const { isSignedIn } = useSelector((state) => state.user)
  const navigate = useNavigate();

  useEffect(() => {
    const loginToken = localStorage.getItem("ADMIN_LOGIN_TOKEN");
    if (loginToken) {
      getProfile().then(resProfile => {
        // console.log('resProfile res', resProfile);
        if (resProfile && resProfile.response.isSuccess) {
          dispatch(setUserInfo(resProfile.user))
          dispatch(setToken(loginToken))
          // dispatch(setIsLoginCompleted(true))
        } else {
          dispatch(setIsLoginCompleted(true))
        }

      }).catch(error => {
        console.error(error)
        dispatch(setIsLoginCompleted(true))
      })
    } else {
      navigate("/login", { replace: true });
    }

  }, []);

  useEffect(() => {

    if (isSignedIn) {
      getPermissionsList().then(res => {
        if (res && res.permission) {
          dispatch(setPermissions(res.permission.children))
        }
        else {
          dispatch(setPermissions([]))
        }
      }).catch(error => {
        console.error(error)
      })

      getRoleList({
        "pageNumber": 1,
        "pageSize": 1000,
        "filters": [
          {
            "fieldName": "role_id",
            "operator": ">",
            "value": "0"
          }
        ],
        "sort": {
          "fieldName": "role_id",
          "sort": "asc"
        }
      }).then(res => {
        if (res && res.roles) {
          dispatch(setRoleList(res.roles))
        }
        else {
          dispatch(setRoleList([]))
        }
      }).catch(error => {
        console.error(error)
      })
    }

  }, [isSignedIn])


  return (
    <></>
  );
}

export default UserProvider;
