import React from 'react';
import defaultClasses from './checkboxNode.module.css';
import { Form, Checkbox } from 'antd';
import {useStyle} from '../../../utils/classify';

const CheckboxNode = props=> {
    const {notFormItem,label, name, valuePropName, children, isGroup, ...checkboxProps} = props;

    const classes = useStyle(defaultClasses, props.classes);
    
    return notFormItem ? (
        <div className={`${classes.root} ${classes.new_root}`}>
            {
                isGroup ? <Checkbox.Group 
                    {...checkboxProps}
                /> : <Checkbox
                    {...checkboxProps}
                >
                    {children}
                </Checkbox>
            }
        </div>
    ) : (
        <Form.Item
            className={`${classes.root} ${classes.new_root}`}
            label={label}
            name={name}
            valuePropName={valuePropName}
        >
            {
                isGroup ? <Checkbox.Group 
                    {...checkboxProps}
                /> : <Checkbox
                    {...checkboxProps}
                >
                    {children}
                </Checkbox>
            }
        </Form.Item>
        
    )
}

export default CheckboxNode;