import React, { useCallback, useEffect, useState } from 'react';
import classes from './switchLanguage.module.css';
import { Space } from 'antd';

const SwitchLanguage = props=>{

    const [localeLan, setLocaleLan] = useState('');

    const handleChangeLan = useCallback((val)=>{
        if(localeLan !== val) {
            localStorage.setItem("localeName", val);
            setLocaleLan(val);
            window.location.reload();
        }
    }, [localeLan]);

    useEffect(()=>{
        const locale = localStorage.getItem("localeName");
        setLocaleLan(locale);
    }, [])

    return (
        <div className={classes.root}>
            <div className={`${classes.lan} ${localeLan == 'en' ? classes.choose : ''}`} onClick={()=>{handleChangeLan('en')}}>
                Eng
            </div>
            <div className={`${classes.lan} ${localeLan == 'hk' ? classes.choose : ''}`} onClick={()=>{handleChangeLan('hk')}}>
                繁
            </div>
            <div className={`${classes.lan} ${localeLan == 'cn' ? classes.choose : ''}`} onClick={()=>{handleChangeLan('cn')}}>
                简
            </div>
        </div>
    )
}

export default SwitchLanguage;