import { zh_CN } from './zh_CN';
import { zh_HK } from './zh_HK';
import { en_US } from './en_US';

function loadLocale(lang) {
    let locale = null;
    let message = null;
    switch (lang) {
        case 'en':
            locale = 'en-US';
            message = en_US;
            break;
        case 'cn':
            locale = 'zh-CN';
            message = zh_CN;
                break;
        case 'hk':
            locale = 'zh-HK';
            message = zh_HK;
                break;
        default:
            locale = 'en-US';
            message = en_US;
            break;
    }
    return { locale, message };
}
export { loadLocale };