import { get, post, put, deleted } from '../utils/http';


//获取用户详情
export function getUserDetailById(id) {
    return get(`roles/${id}`);
};

//修改用户信息
export function editUserDetail(param) {
    return put(`roles/${param.id}`, param);
};

//创建用户
export function createUserDetail(param) {
    return post('roles', param);
};

//删除用户信息
export function delUserDetail(id) {
    return deleted(`roles/${id}`);
};

//用户信息数据列表
export function getUserList(param) {
    return post('roles/list', param);
};

//给用户分配角色
export function setUserRole(param) {
    return post('role/bind', param);
};

//查询可配置的权限列表
export function getPermissionsList(param) {
    return get('roles/permissions/list', param);
}